<template>
    <div>
      <b-modal
        id="modal-edit-ruang"
        size="lg"
        centered
        title="Edit Data Master Ruang"
        header-bg-variant="warning"
        header-text-variant="light"
      >
        <b-form>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Nama Ruang<span class="text-danger">*</span>
            </template>
            <b-form-input
              :state="checkIfValid('nama_ruang')"
              type="text"
              v-model="$v.data.nama_ruang.$model"
              @keydown.enter.prevent="simpan()"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Instalasi <span class="text-danger">*</span>
            </template>
            <b-form-select
              :state="checkIfValid('instalasi')"
              :options="options_intalasi"
              v-model="$v.data.instalasi.$model"
            ></b-form-select>
          </b-form-group>
        </b-form>
  
        <template #modal-footer>
          <b-button variant="secondary" @click="$bvModal.hide('modal-edit-ruang')">
            Batal
          </b-button>
          <b-button
            variant="primary"
            :disabled="busy || !isValid"
            @click="simpan()"
          >
            {{ button }}
          </b-button>
        </template>
      </b-modal>
    </div>
  </template>
  <script>
  import _ from "lodash";
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  export default {
    name: "modalEdit",
    props: ["data"],
    data() {
      return {
        busy: false,
        button: "Simpan",
        options_intalasi: [
          {value: "RAJAL", text: "RAJAL"},
          {value: "RANAP", text: "RANAP"},
          {value: "IGD", text: "IGD"},
        ],
      };
    },
    computed: {
      formString() {
        return JSON.stringify(this.data, null, 4);
      },
      isValid() {
        return !this.$v.data.$invalid;
      },
      isDirty() {
        return this.$v.data.$anyDirty;
      },
    },
    mixins: [validationMixin],
    validations: {
      data: {
        nama_ruang: {
          required,
        },
        instalasi: {
          required,
        }
      },
    },
    methods: {
      checkIfValid(fieldName) {
        const field = this.$v.data[fieldName];
        if (!field.$dirty) {
          return null;
        }
        return !(field.$invalid || field.$model === "");
      },
      simpan() {
        let vm = this;
        if (vm.isValid) {
          vm.busy = true;
          vm.button = "Mohon Tunggu";
          this.$axios.post("/ms_ruang/update", {...vm.data, id: vm.data.ms_ruang_id})
            .then((res) => {
              if (res.data.message == "sukses") {
                vm.button = "Simpan";
                vm.busy = false;
                vm.$emit("alertFromChild", {
                  variant: "success",
                  msg: "BERHASIL MENGUBAH DAFTAR MASTER RUANG",
                  showing: true,
                });
                this.$bvModal.hide("modal-edit-ruang");
                this.data.type = "";
              } else {
                vm.button = "Simpan";
                vm.busy = false;
                vm.$emit("alertFromChild", {
                  variant: "danger",
                  msg: _.toUpper(res.data.message),
                  showing: true,
                });
              }
            })
            .catch((err) => {
              console.log(err);
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "danger",
                msg: "TERJADI KESALAHAN PADA SERVER",
                showing: true,
              });
            });
        }
      },
    },
  };
  </script>
  
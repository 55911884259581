<template>
    <div>
      <b-modal
        id="modal-input-ruang"
        size="lg"
        centered
        title="Tambah Data Master Ruang"
        header-bg-variant="primary"
        header-text-variant="light"
      >
        <b-form>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Nama Ruang <span class="text-danger">*</span>
            </template>
            <b-form-input
              :state="checkIfValid('nama_ruang')"
              type="text"
              v-model="$v.data.nama_ruang.$model"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Instalasi <span class="text-danger">*</span>
            </template>
            <b-form-select
              :state="checkIfValid('instalasi')"
              :options="options_intalasi"
              v-model="$v.data.instalasi.$model"
            ></b-form-select>
          </b-form-group>
        </b-form>
        <!-- <img :src="src1" /> -->
        <template #modal-footer>
          <b-button variant="secondary" @click="$bvModal.hide('modal-input-ruang')">
            Batal
          </b-button>
          <b-button
            variant="primary"
            :disabled="busy || !isValid"
            @click="simpan()"
            >{{ button }}</b-button
          >
        </template>
      </b-modal>
    </div>
  </template>
  
  <script>
  import _ from "lodash"
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  
  export default {
    name: "modalInput",
    props: ['option_ruang'],
    data() {
      return {
        data: {
          nama_ruang: "",
          instalasi: null,
        },
        options_intalasi: [
          {value: "RAJAL", text: "RAJAL"},
          {value: "RANAP", text: "RANAP"},
          {value: "IGD", text: "IGD"},
        ],
        src1: "",
        selected: null,
        busy: false,
        button: "Simpan",
      };
    },
    computed: {
      formString() {
        return JSON.stringify(this.data, null, 4);
      },
      isValid() {
        return !this.$v.data.$invalid;
      },
      isDirty() {
        return this.$v.data.$anyDirty;
      },
    },
    mixins: [validationMixin],
    validations: {
      data: {
        nama_ruang: {
          required,
        },
        instalasi: {
          required,
        },
      },
    },
    methods: {
      checkIfValid(fieldName) {
        const field = this.$v.data[fieldName];
        if (!field.$dirty) {
          return null;
        }
        return !(field.$invalid || field.$model === "");
      },
      simpan() {
        let vm = this;
        vm.busy = true;
        vm.button = "Mohon Tunggu";
        this.$axios.post("/ms_ruang/register", vm.data)
          .then((res) => {
            console.log(res, "ini ress");
            if (res.data.message == "sukses") {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$v.$reset();
              vm.$emit("alertFromChild", {
                variant: "success",
                msg: "BERHASIL MENDAFTARKAN MASTER RUANG",
                showing: true,
              });
              this.$bvModal.hide("modal-input-ruang");
              this.reset();
            } else {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "danger",
                msg: _.toUpper(res.data.message),
                showing: true,
              });
            }
          })
          .catch((err) => {
            console.log(err)
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: "TERJADI KESALAHAN PADA SERVER",
              showing: true,
            });
          });
      },
      reset() {
        this.$v.$reset();
        this.data = {
          nama_ruang: "",
          instalasi: ""
        };
      },
    },
  };
  </script>
  